
import { FC, useEffect, useState } from 'react'

import axios from 'axios'

import { useRecoilState, useRecoilValue } from 'recoil'
import { callbackStateAtom } from '@state/callback'
import { callBackAttachDataStateAtom } from '@state/callBackAttachData'

import MuiPhoneNumber from 'material-ui-phone-number'

import { useForm, Controller } from 'react-hook-form'

import * as React from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { LoadingButton } from '@mui/lab'

import TextField from '@mui/material/TextField'

import SwipeableDrawer from '@components/SwipeableDrawer'

import SendIcon from '@mui/icons-material/Send';

import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup"

import { styled } from '@mui/material/styles';

import Typography from '@mui/material/Typography';

import DoneIcon from '@mui/icons-material/Done';

const Schema = yup.object().shape({
  name: yup.string().required("Необходимо ввести имя"),
  phone: yup.string().min(18).required("Необходимо ввести телефон")
})

const  {REACT_APP_API_URL} = process.env

const CallBack: FC<any> = () => {
  
  const [ loading, setLoading ] = useState<boolean>(false)
  const [ send, setSend ] = useState<boolean>(false)
  const [ callback, setCallback ] = useRecoilState<boolean>(callbackStateAtom)
  
  const callBackAttachData = useRecoilValue<any>(callBackAttachDataStateAtom)
  
  const { handleSubmit, control, getValues, formState: { errors } } = useForm({
    resolver: yupResolver(Schema),
    defaultValues: {
      name: '',
      phone: ''
    }
  })

  useEffect(() => {
    if(send){
      setTimeout(()=>{
        setCallback(false)
      }, 2500)
    }
  }, [send, setCallback])
  
  const onSubmit = async (data:any) => {
    setLoading(true)
    axios({
      method: 'POST',
      url: `${REACT_APP_API_URL}/call-back`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        name: getValues('name'),
        phone: getValues('phone'),
        url: callBackAttachData.url
      }
    })
    .then(() => {
      setSend(true)
      setLoading(false)
      console.log(data)
    })
    .catch((error:any) => {
      setLoading(false)
      console.log(error)
    })
  }
  
  const close = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.stopPropagation()
    setCallback(false)
  }

  const onClose = () => {
    setCallback(false)
  }

  if(send){
    return ( 
      <>
          <SwipeableDrawer
            anchor={'bottom'}
            open={callback}
            onClose={onClose}
            onOpen={()=>{}}
          >

              <Box sx={{'justify-content': 'center','display': 'flex', 'align-items': 'center', 'flex-wrap': 'wrap'}}>

                <DoneIcon  sx={{mt: 3, fontSize: 100, color: 'rgb(53, 207, 61)'}}/>
      
                <Typography color="textSecondary" sx={{m: 1, mb: 5, fontSize: 17, textAlign: "center"}}>

                  В ближайшее время наш менеджер свяжется с вами!
                  
                </Typography>

              </Box>

          </SwipeableDrawer>
      </>
    )
  }

  return ( 
    <>
        <SwipeableDrawer
          anchor={'bottom'}
          open={callback}
          onClose={onClose}
          onOpen={()=>{}}
        >
            <>

              <Typography color="textSecondary" sx={{m: 2.5, fontSize: 18, textAlign: "center"}}>

                Оставьте свои данные ниже, мы свяжемся с вами в ближайшее время!
                  
              </Typography>

              <Box
                component="form"
                sx={{
                  padding: 1,
                  '& > :not(style)': { m: 1, width: '95.5%' },
                }}
                noValidate
                autoComplete="off"
              >

                <Controller
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { isTouched, isDirty, error },
                  }) => (
                    <TextField
                      placeholder="Имя"
                      value={value}
                      onBlur={onBlur}
                      inputRef={ref}
                      onChange={onChange}
                    />
                  )}
                  name="name"
                  control={control}
                  rules={{ required: true }}
                />

                {errors?.name && <Typography variant="overline" color="error">{errors.name.message}</Typography>}

                <Controller
                  name="phone"
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <MuiPhoneNumber
                    variant="outlined"
                    defaultCountry={'ru'}
                    regions={'europe'}
                    onChange={onChange}
                    value={value}
                    />
                  )}
                />
                
                {errors?.phone && errors.phone.type === "required" && <Typography variant="overline" color="error">{errors.phone.message}</Typography>}
                {errors?.phone && errors.phone.type === "min" && <Typography variant="overline" color="warning">телефон должен быть не менее 11 символов</Typography> }

                <LoadingButton loading={loading} variant="outlined" onClick={handleSubmit(onSubmit)} endIcon={<SendIcon />}>Отправить</LoadingButton>
                
                <Grid>  
                  <Typography color="textSecondary" align="center" sx={{fontSize: 10}}>Нажимая на кнопку, вы даете согласие на обработку персональных данных и соглашаетесь c политикой конфиденциальности.</Typography>
                </Grid>

              </Box>

              </>
            
        </SwipeableDrawer>
    </>
  )
}

export default CallBack
