import { FC } from 'react'

import TuneIcon from '@mui/icons-material/Tune'

import Fade from '@mui/material/Fade'

import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { pageStateAtom } from '@state/page'
import { filtersStateAtom } from '@state/filters'

const ButtonFilters: FC<any> = () => {

  const [page, setPage] = useRecoilState<any>(pageStateAtom)

  const setFilters = useSetRecoilState<any>(filtersStateAtom)

  return (
    <>
    <Fade in={page === 'products'} timeout={700}>

      <TuneIcon sx={{
        position: 'fixed',
        top: 25,
        right: 75,
      }}
      onClick={()=>{
        setFilters((prevState:any) => ({ ...prevState, open: true }))
      }}
      />
    </Fade>
    </>
  )
}

export default ButtonFilters
