import { atom, selector } from "recoil"

export const productStateAtom = atom({
  key: "productStateAtom",
  default: null,
})

export const productStateSelector = selector({
  key: "productStateSelector",
  get: ({ get }) => {
    const product = get(productStateAtom)
    return product
  }
})

