import { useEffect, useState } from 'react';

function useUrlParams() {
  const [params, setParams] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    // Получение текущего URL
    const currentUrl = window.location.href;

    // Разбивка URL на части, чтобы получить параметры
    const urlParts = currentUrl.split('?');

    // Проверка наличия параметров в URL
    if (urlParts.length > 1) {
      const queryParams = urlParts[1].split('&');

      // Создание объекта для хранения параметров
      const paramsObj: { [key: string]: string } = {};

      // Парсинг параметров из URL
      queryParams.forEach((param) => {
        const keyValue = param.split('=');
        const key = decodeURIComponent(keyValue[0]);
        const value = decodeURIComponent(keyValue[1]);
        paramsObj[key] = value;
      });

      // Установка параметров в состояние
      setParams(paramsObj);
    }
  }, []);

  return params;
}

export default useUrlParams;

