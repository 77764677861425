

import { FC, useState, useEffect } from 'react'

import axios from 'axios'

import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import CircularProgress from '@mui/material/CircularProgress'

import { useSetRecoilState, useRecoilValue, useRecoilState } from 'recoil'

import { modelsStateAtom } from '@state/models'

import { brandStateAtom } from '@state/brand'
import { modelStateAtom } from '@state/model'

import { pageStateAtom } from '@state/page'

import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import { ListChildComponentProps } from 'react-window'

import useMeasure from '@hooks/useMeasure'

import { styled } from '@mui/material/styles'

import VirtualList from "@components/VirtualList"

const renderRow = (props: ListChildComponentProps) => {

  const { data, index, style } = props;

  return (
    <ListItem style={style} key={index} component="div" disablePadding onClick={() => data.onClick(data?.data[index].key, data?.data[index].name)}>
      <ListItemButton disableRipple>
        <Stack
          key={data?.data[index].key}
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
          >
            <Item >
              {data?.data[index].name}
            </Item>
          </Stack>
      </ListItemButton>
    </ListItem>
  )
}

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  marginTop: 10,
  marginBottom: 10,
  marginLeft: 15,
  color: theme.palette.text.secondary,
}));

const  {REACT_APP_API_URL} = process.env

const Products: FC<any> = () => {

  const [models , setModels] = useRecoilState<any>(modelsStateAtom)

  const setModel = useSetRecoilState<any>(modelStateAtom)
  const setPage = useSetRecoilState<any>(pageStateAtom)
  
  const brand = useRecoilValue<any>(brandStateAtom)
  
  const [loading, setLoading] = useState<boolean>(false)

  const { windowSize } = useMeasure();

  useEffect(() => {

    console.log(`models: ${models.length}`)

    if(models.length===0){

      setLoading(true)

      axios({
        method: 'POST',
        url: `${REACT_APP_API_URL}/get-all-models-for-brand`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          brand: brand,
        }
      })
      .then((data) => {

        if(data.status===200){

          setLoading(false)

          setModels(data.data)
          console.log(data)
        }
      })
      .catch((error:any) => {

        setLoading(false)

        console.log(error)
      })
    }
  }, [])

  const onClick = (key: string, name: string) => {
    setModel(name)
    setPage('products')
  }
  
  return <Container maxWidth="sm">

          <Typography variant="subtitle1" gutterBottom color="primary" align="center" sx={{ mt: 1}}>
            
            {brand}

          </Typography>

          <Typography variant="subtitle2" gutterBottom align="center" sx={{ mt: 3}}>

            Выберите модель

          </Typography>

          { models?.length > 0 &&

            <VirtualList
            itemCount = {models.length}
            data = {models}
            itemSize = {80}
            loadMoreItems = {()=>{}}
            onClick = {onClick}

            renderRow= {renderRow}
            height={windowSize.height - 180}
            width={windowSize.width - 40}
            />
            
          }

          { loading &&
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"  // Center vertically
              justifyContent="center"  // Center horizontally
              height="70vh"  // Optional: Set a specific height for centering in the viewport
            >

              <CircularProgress color="inherit" />

              <Typography variant="subtitle2" gutterBottom  align="center" sx={{ mt: 2.5 , ml: 5, mr: 5}}>

                Загружаем актуальные данные

              </Typography>
              
            </Box>
          }

          {  !loading && models.length === 0 &&

            <Typography variant="subtitle2" gutterBottom color="primary" align="center" sx={{ mt: 1, pt: '50%'}}>

              Что-то пошло не так! Данные, которую вы хотели получить, потеряны!

            </Typography>
          }

        </Container>
}

export default Products

