
import { FC } from 'react'

import { useRecoilState } from 'recoil'
import { filtersStateAtom } from '@state/filters'

import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'

import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  customCursor: {
    cursor: 'pointer', // Устанавливаем пользовательский стиль курсора
  },
})

interface Option {
  key: string;
  name: string;
}

const options: Option[] = [
  { key: '1.0L及以下', name: '1.0L и ниже' },
  { key: '1.1L-1.6L', name: '1.1L-1.6L' },
  { key: '1.7L-2.0L', name: '1.7L-2.0L' },
  { key: '2.1L-2.5L', name: '2.1L-2.5L' },
  { key: '2.6L-3.0L', name: '2.6L-3.0L' },
  { key: '3.1L-4.0L', name: '3.1L-4.0L' },
  { key: '4.0L以上', name: '4.0L и больше' },
]
 
const EngineCapacity: FC = () => {

  const classes = useStyles()

  const [filters, setFilters] = useRecoilState<any>(filtersStateAtom)

  return (

          <Autocomplete
            value={filters.engineCapacity}
            onChange={(event, newValue) => {
              setFilters((prevState:any) => ({ ...prevState, engineCapacity: newValue }));
            }}
            options={options}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={filters.engineCapacity ? '' : 'Объем'}
                variant="outlined"
                fullWidth
                inputProps={{
                  ...params.inputProps,
                  readOnly: true,
                  className:  classes.customCursor, // Применяем пользовательский класс CSS при не выбранной опции
                }}
              />
            )}
          />
  )
}

export default EngineCapacity;
