import { atom, selector } from "recoil"

export const brandsStateAtom = atom({
  key: "brandsStateAtom",
  default: [],
})

export const brandsStateSelector = selector({
  key: "brandsStateSelector",
  get: ({ get }) => {
    const brands = get(brandsStateAtom)
    return brands
  }
})

