import { FC, useEffect  } from 'react'

import { useRecoilState } from 'recoil'

import { SwipeableDrawerTelegramFixedStateAtom } from '@state/SwipeableDrawerTelegramFixed'

import SwipeableDrawer from '@mui/material/SwipeableDrawer'

interface I {
  open: boolean;
  onOpen: () => void;
  onClose: () => void;
  anchor: "top" | "left" | "right" | "bottom" | undefined;
  sx?: any;
  children: any;
}

const CustomSwipeableDrawer: FC<I> = ({ open=false, onOpen, onClose, sx, anchor='top', children }) => {

  const [swipeableDrawerTelegramFixed, SetSwipeableDrawerTelegramFixed] = useRecoilState<any>(SwipeableDrawerTelegramFixedStateAtom)

  useEffect(() => {
    console.log(`swipeableDrawerTelegramFixed: ${swipeableDrawerTelegramFixed}`)
  }, [swipeableDrawerTelegramFixed])

  useEffect(()=>{

    if(open){

      if(swipeableDrawerTelegramFixed === 0){

        const elem = document.body

        if(elem){
  
          elem.style.height = '101vh'
        }
        window.scrollTo(0, 1)

      }
      SetSwipeableDrawerTelegramFixed((prevState:number) => ( prevState + 1 ));

    }else{

      if(swipeableDrawerTelegramFixed===1){

        window.scrollTo(0, 0)

        const elem = document.body

        if(elem){

          elem.style.height = '100vh'
        }

      }
      SetSwipeableDrawerTelegramFixed((prevState:number) => ( prevState > 0 ? prevState - 1 : prevState ));


    }
  }, [open])

  

  return (
      <>
        <SwipeableDrawer
          anchor={anchor}
          open={open}
          onClose={onClose}
          onOpen={onOpen}
          minFlingVelocity={50}
          sx={ sx ? {...sx, "&" : {
            //backgroundColor: 'rgba(255, 255, 255, 0.3)',
            backdropFilter: "blur(2px)",
            width: "100%",
          },} : { 
            "&" : {
              //backgroundColor: 'rgba(255, 255, 255, 0.3)',
              backdropFilter: "blur(2px)",
              width: "100%",
            },
            '& .MuiPaper-root': {
              borderTopLeftRadius: 15,
              borderTopRightRadius: 15,
            },
          }}
        >
          {children}
        </SwipeableDrawer>
    </>
  )
}

export default CustomSwipeableDrawer
