import { atom, selector } from "recoil"

export const modelsStateAtom = atom({
  key: "modelsStateAtom",
  default: [],
})

export const modelsStateSelector = selector({
  key: "modelsStateSelector",
  get: ({ get }) => {
    const models = get(modelsStateAtom)
    return models
  }
})

