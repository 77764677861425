import { FC } from 'react'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'

import Fade from '@mui/material/Fade'
import { useRecoilState, useSetRecoilState, useResetRecoilState } from 'recoil'

import { pageStateAtom } from '@state/page'
import { pageNumberStateAtom } from '@state/pageNumber'
import { productsStateAtom } from '@state/products'
import { modelsStateAtom } from '@state/models'
import { brandStateAtom } from '@state/brand'
import { modelStateAtom } from '@state/model'
import { SwipeableDrawerTelegramFixedStateAtom } from '@state/SwipeableDrawerTelegramFixed'
import { filtersStateAtom } from '@state/filters'

const ButtonBack: FC<any> = () => {
  
  const SetSwipeableDrawerTelegramFixed = useSetRecoilState<number>(SwipeableDrawerTelegramFixedStateAtom)

  const [page, setPage] = useRecoilState<any>(pageStateAtom)
  const setPageNumber = useSetRecoilState<number>(pageNumberStateAtom)
  const setModel = useSetRecoilState<any>(modelStateAtom)
  const setBrand = useSetRecoilState<any>(brandStateAtom)
  const setModels = useSetRecoilState<any>(modelsStateAtom)
  const setProducts = useSetRecoilState<any>(productsStateAtom)

  const filtersReset = useResetRecoilState(filtersStateAtom)
  
  return (
    <>
    <Fade in={page !== 'brands'} timeout={700}>

      <ArrowBackIcon sx={{
        position: 'fixed',
        top: 25,
        right: 15,
      }}
      onClick={()=>{
        if(page==='models'){
          setBrand(null)
          setPage('brands')
          setPageNumber(1)
          setModels([])
        }else
        if(page==='products'){
          setModel(null)
          setPage('models')
          setPageNumber(1)
          setProducts([])
          filtersReset()
        }
        SetSwipeableDrawerTelegramFixed(0)
      }}
      />
    </Fade>
    </>
  )
}

export default ButtonBack
