
import { FC } from 'react'

import { useRecoilState } from 'recoil'
import { filtersStateAtom } from '@state/filters'

import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'

import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  customCursor: {
    cursor: 'pointer', // Устанавливаем пользовательский стиль курсора
  },
})

interface Option {
  key: string;
  name: string;
}

const options: Option[] = [
  { key: '手动', name: 'Автомат' },
  { key: '自动', name: 'Механика' },
]
 
const Transmission: FC = () => {

  const classes = useStyles()

  const [filters, setFilters] = useRecoilState<any>(filtersStateAtom)

  return (

          <Autocomplete
            value={filters.transmission}
            onChange={(event, newValue) => {
              setFilters((prevState:any) => ({ ...prevState, transmission: newValue }));
            }}
            options={options}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={filters.transmission ? '' : 'КПП'}
                variant="outlined"
                fullWidth
                inputProps={{
                  ...params.inputProps,
                  readOnly: true,
                  className:  classes.customCursor, // Применяем пользовательский класс CSS при не выбранной опции
                }}
              />
            )}
          />
  )
}

export default Transmission;
