
import { FC } from 'react'

import { useRecoilState } from 'recoil'
import { filtersStateAtom } from '@state/filters'

import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'

import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  customCursor: {
    cursor: 'pointer', // Устанавливаем пользовательский стиль курсора
  },
})

interface Option {
  key: string;
  name: string;
}

const options: Option[] = [
  { key: '汽油', name: 'Бензин' },
  { key: '柴油', name: 'Дизель' },
  { key: '新能源', name: 'Электро' },
  { key: '油电混合', name: 'Гибрид' },
]

const Fuel: FC = () => {

  const classes = useStyles()

  const [filters, setFilters] = useRecoilState<any>(filtersStateAtom)

  return (
          <Autocomplete
            value={filters.fuel}
            onChange={(event, newValue) => {
              setFilters((prevState:any) => ({ ...prevState, fuel: newValue }))
            }}
            options={options}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={filters.fuel ? '' : 'Топливо'}
                variant="outlined"
                fullWidth
                inputProps={{
                  ...params.inputProps,
                  readOnly: true,
                  className:  classes.customCursor, // Применяем пользовательский класс CSS при не выбранной опции
                }}
              />
            )}
          />
  )
}

export default Fuel;
