import { atom, selector } from "recoil"

export const brandStateAtom = atom({
  key: "brandStateAtom",
  default: null,
})

export const brandStateSelector = selector({
  key: "brandStateSelector",
  get: ({ get }) => {
    const brand = get(brandStateAtom)
    return brand
  }
})

