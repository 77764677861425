import { atom, selector } from "recoil"

export const callbackStateAtom = atom({
  key: "callbackStateAtom",
  default: false,
})

export const callbackStateSelector = selector({
  key: "callbackStateSelector",
  get: ({ get }) => {
    const callback = get(callbackStateAtom)
    return callback
  }
})

