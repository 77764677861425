import { FC } from 'react'

import Box from '@mui/material/Box'

import { useRecoilState } from 'recoil'
import { pageStateAtom } from '@state/page'

const Logo: FC<any> = () => {

  const [page, setPage] = useRecoilState<any>(pageStateAtom)

  return (
      <Box
        component="img"
        sx={{

          height: 35,
          width: 200,
          maxHeight: { xs: 233, md: 167 },
          maxWidth: { xs: 350, md: 250 },
          transition:' all 1s ease-out',
          marginLeft: page !== 'brands' ? 0 : 'calc(50% - 100px)',
          position: 'relative',

        }}
        alt="logo"
        src="/assets/logo.svg"
      />
  )
}

export default Logo
