import { atom, selector } from "recoil"

export const SwipeableDrawerTelegramFixedStateAtom = atom({
  key: "SwipeableDrawerTelegramFixedStateAtom",
  default: 0,
})

export const SwipeableDrawerTelegramFixedStateSelector = selector({
  key: "SwipeableDrawerTelegramFixedStateSelector",
  get: ({ get }) => {
    const SwipeableDrawerTelegramFixed = get(SwipeableDrawerTelegramFixedStateAtom)
    return SwipeableDrawerTelegramFixed
  }
})

