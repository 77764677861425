import { atom, selector } from "recoil"

type filterStateItemI = {
  key: string | number | null;
  name: string | number |  null;

}

interface minMax {
  min: filterStateItemI | null
  max: filterStateItemI | null
}

type filterStateI = {
  open: boolean;
  drive: string | null;
  year: object | null[];
/*   engineCapacity: object | null[]; */
  engineCapacity: filterStateItemI | null;
  fuel: filterStateItemI | null;
  transmission: filterStateItemI | null;
  price: minMax | null[];
}

export const filtersStateAtom = atom<filterStateI>({
  key: "filtersStateAtom",
  default: {
    open: false,
    drive: null,
/*     engineCapacity: {
      min: null,
      max: null,
    }, */
    year: {
      min: null,
      max: null,
    },
    engineCapacity: null,
    fuel: null,
    transmission: null,
    price: {
      min: null,
      max: null,
    },
  }
})

export const filtersStateSelector = selector({
  key: "filtersStateSelector",
  get: ({ get }) => {
    const filters = get(filtersStateAtom)
    return filters
  }
})

export type { filterStateI, filterStateItemI }
