
import { FC, useState, useEffect } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';

import axios from 'axios'

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import CircularProgress from '@mui/material/CircularProgress';

import { useSetRecoilState, useRecoilState } from 'recoil'

import { brandsStateAtom } from '@state/brands'
import { brandStateAtom } from '@state/brand'
import { pageStateAtom } from '@state/page'
import { SwipeableDrawerTelegramFixedStateAtom } from '@state/SwipeableDrawerTelegramFixed'

import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import { FixedSizeList, ListChildComponentProps } from 'react-window';

import useMeasure from '@hooks/useMeasure';
import { styled } from '@mui/material/styles';

import VirtualList from "@components/VirtualList"

const renderRow = (props: ListChildComponentProps) => {

  const { data, index, style } = props;

  return (
    <ListItem style={style} key={index} component="div" disablePadding onClick={() => data.onClick(data?.data[index].key, data?.data[index].name)}>
      <ListItemButton disableRipple>
        <Stack
          key={data?.data[index].key}
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
        >
          <Item>
            <LazyLoadImage
              alt={data?.data[index].name}
              height={64}
              /* src={data?.data[index].image.includes('https') ? data?.data[index].image : '/assets/images/'+data?.data[index].image}  */
              src={data?.data[index].image} 
              width={64} />
          </Item>
          <Item>
            {data?.data[index].name}
          </Item>
        </Stack>
      </ListItemButton>
    </ListItem>
  )
}

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const  {REACT_APP_API_URL} = process.env

const Brands: FC<any> = () => {

  const { windowSize } = useMeasure();
  
  const [swipeableDrawerTelegramFixed, SetSwipeableDrawerTelegramFixed] = useRecoilState<any>(SwipeableDrawerTelegramFixedStateAtom)

  const [brands , setBrands] = useRecoilState<any>(brandsStateAtom)
  const setPage = useSetRecoilState<any>(pageStateAtom)
  const setBrand = useSetRecoilState<any>(brandStateAtom)
  
  const [loading, setLoading] = useState<boolean>(false)

  const onClick = (key: string, name: string) => {

    console.log(`key: ${key}, name: ${name}`)
    setBrand(name)
    setPage('models')
  }

  useEffect(() => {
  
    if(brands.length===0){

      setLoading(true)

      axios({
        method: 'POST',
        url: `${REACT_APP_API_URL}/get-brands`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: {}
      })
      .then((data) => {

        if(data.status===200){

          setBrands(data.data)
          setLoading(false)

          console.log(data)
        }
      })
      .catch((error:any) => {

        setLoading(false)
        console.log(error)
      })
    }
  }, [])

  return <Container maxWidth="sm">

          <Typography variant="subtitle1" gutterBottom color="primary" align="center" sx={{ mt: 1}}>

            Автомобили с аукционов Китая в режиме реального времени.

          </Typography>

          <Typography variant="subtitle2" gutterBottom align="center" sx={{ mt: 3}}>

            Выберите бренд

          </Typography>

          { brands?.length > 0 &&

            <VirtualList
              itemCount = {brands.length}
              data = {brands}
              itemSize = {80}
              loadMoreItems = {()=>{}}
              onClick = {onClick}
              
              renderRow= {renderRow}
              height={windowSize.height - 180}
              width={windowSize.width - 40}
            />
            
          }

          { loading &&
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"  // Center vertically
              justifyContent="center"  // Center horizontally
              height="70vh"  // Optional: Set a specific height for centering in the viewport
            >

              <CircularProgress color="inherit" />

              <Typography variant="subtitle2" gutterBottom  align="center" sx={{ mt: 2.5 , ml: 5, mr: 5}}>

                Загружаем актуальные данные

              </Typography>
              
            </Box>
          }

          {  !loading && brands.length === 0 &&

            <Typography variant="subtitle2" gutterBottom color="primary" align="center" sx={{ mt: 1, pt: '50%'}}>

              Что-то пошло не так! Попробуйте зайти позже.
              
            </Typography>
          }

{/* 
          {brands?.map((item:any) => (
            <Stack
            key={item.key}
            onClick={() => onClick(item.key, item.name)}

            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
            >
              <Item >
                <LazyLoadImage
                  alt={item.name}
                  height={64}
                  src={'/assets/images/'+item.image} 
                  width={64} />
              </Item>
              <Item >
                {item.name}
              </Item>
            </Stack>
          ))}
 */}

        </Container>
}

export default Brands

