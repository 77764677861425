import { FC, useState, useEffect } from 'react'

import { useRecoilState } from 'recoil'
import { SwipeableDrawerTelegramFixedStateAtom } from '@state/SwipeableDrawerTelegramFixed'

import { FixedSizeList as List } from 'react-window'

import InfiniteLoader from "react-window-infinite-loader"

const VirtualList: FC<any> = ({
  itemCount = 0,
  data = [],
  itemSize = 140,
  loadMoreItems = ()=>{},
  onClick = ()=>{},
  renderRow= ()=>{},
  height=300,
  width=100,
  isItemLoaded = (index: number) => {
    return index < data.length;
  },
}) => {

  const [swipeableDrawerTelegramFixed, SetSwipeableDrawerTelegramFixed] = useRecoilState<any>(SwipeableDrawerTelegramFixedStateAtom)

  const [scrolled, setScrolled] = useState(false)

  const handleScroll = (scroll:any) => {

    if (scroll.scrollOffset > 10) {

      setScrolled(true)
    }else
    if (scroll.scrollOffset < 10) {
      
      setScrolled(false)
    }
  }

  useEffect(()=>{

  }, [swipeableDrawerTelegramFixed])

  useEffect(()=>{

    if(scrolled){

        if(swipeableDrawerTelegramFixed === 0){
  
          const elem = document.body
  
          if(elem){
    
            elem.style.height = '101vh'
          }
          window.scrollTo(0, 1)

        }

        SetSwipeableDrawerTelegramFixed((prevState:number) => ( prevState + 1 ));

      }else{
  
        if(swipeableDrawerTelegramFixed === 1){
  
          window.scrollTo(0, 0)
  
          const elem = document.body
  
          if(elem){
  
            elem.style.height = '100vh'
          }

        }
        
        SetSwipeableDrawerTelegramFixed((prevState:number) => ( prevState > 0 ? prevState - 1 : prevState ));
      }
  }, [scrolled])

  return (
    <>
      <InfiniteLoader
        isItemLoaded={isItemLoaded}
        itemCount={itemCount}
        loadMoreItems={loadMoreItems}
      >
      {({ onItemsRendered, ref }) => (
        <List
          height={height}
          width={width}
          itemCount={data.length}
          itemSize={itemSize}
          itemData={{
            data: data,
            onClick: onClick,
          }}
          onItemsRendered={onItemsRendered}
          ref={ref}
          onScroll={handleScroll} // Обработчик события прокрутки
          overscanCount={5}
        >
          {renderRow}
        </List>
      )}
  
    </InfiniteLoader>
    </>
  )
}

export default VirtualList
