import { atom, selector } from "recoil"

export const callBackAttachDataStateAtom = atom({
  key: "callBackAttachDataStateAtom",
  default: null,
})

export const callBackAttachDataStateSelector = selector({
  key: "callBackAttachDataStateSelector",
  get: ({ get }) => {
    const callBackAttachData = get(callBackAttachDataStateAtom)
    return callBackAttachData
  }
})

