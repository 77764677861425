
import './App.css'

import WebFont from 'webfontloader'

import { useEffect } from 'react'

import Header from '@components/Header'

import CallBack from '@components/CallBack'
import Brands from '@components/Brands'
import Models from '@components/Models'
import Products from '@components/Products'
import Product from '@components/Product'
import CallBackButton from '@components/CallBackButton'

import { useRecoilValue } from 'recoil'
import { pageStateAtom } from '@state/page'

import { createTheme, ThemeProvider } from '@mui/material/styles'

import grey from '@mui/material/colors/grey'


const borderRadius = 5

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 980,
      lg: 1240,
      xl: 1626,
    },
  },
  palette: {
    primary: grey,
    secondary: grey,
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          paddingTop: 24,
          userSelect: 'none',
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: borderRadius,
          borderColor: 'none',
          border: 0, 
          '& .MuiOutlinedInput-root': {
            borderRadius: borderRadius,
            height: 50,
            border: '0px solid #909090',
            padding: 5,

            ':hover': {
              border: '0px solid #fd0000 !important',
              boxShadow: '-1px 1px 4px 4px rgba(219, 219, 219, 0.25);',
              borderRadius: borderRadius,
              borderColor: 'none',
            },
            ':focus-within': { border: '0px solid #fd0000 !important' }
          },
          '& .MuiOutlinedInput-root.Mui-disabled': {
            ':hover': {
              border: '0px solid #909090 !important',
              boxShadow: 'none'
            }
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none'
          },
          '& .MuiOutlinedInput-input': {
            padding: 10,
          }
        },
      }
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '& .MuiPaper-root': {
            backgroundColor: 'unset',
          }
        }
      }
    },
    MuiPaper:{
      styleOverrides: {
        root: {
          "box-shadow": "unset",
        }
      }
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          //backgroundColor: "rgb(52, 52, 52)",

        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
        },
        paper: {
          backgroundColor: 'rgba(255, 255, 255, 1)',
          borderRadius: borderRadius,
          boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)', // Добавление тени к выпадающему списку

        },
        option: {
          '&[aria-selected="true"]': {
            backgroundColor: '#e3abed',
          },
          '&': {
            //backgroundColor: '#fff',
          },
        },
      },

    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          background: 'unset',

          border: 0,
          ':hover': {
            background: 'unset',

          },
        }
      }
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          background: 'unset',

          border: 0,
          ':hover': {
            background: 'red',

          },
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        root:{
          userSelect: 'none',
        }
      }
    },
    MuiInputBase: {
        styleOverrides: {
          root: {
            color: '#000',
            background: '#F3F3F3',
            borderWidth: '26px',
            borderRadius: '25px',
            borderColor: 'none',
            border: 0,

            ':hover': {
              borderRadius: '25px',
              borderColor: 'none',
              border: 0,
            },
            '& .MuiOutlinedInput-root': {
              borderRadius: '7px',
              height: 50,
              border: '1px solid #909090',
              
              ':hover': {
                border: '0px solid #fd0000 !important',
                boxShadow: '-1px 1px 4px 4px #FFEAEA'
              },
              ':focus-within': { border: '0px solid #fd0000 !important' }
            },
            '& .MuiOutlinedInput-root.Mui-disabled': {
              ':hover': {
                border: '0px solid #909090 !important',
                boxShadow: 'none',
              }
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none'
            }
          },
        }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 15,
          padding: 20,

          backgroundColor: 'rgba(208,0,0,.8)',
          color: '#fff'
        }
      }
    }
},
transitions: {
  duration: {
      enteringScreen: 1000,
      leavingScreen: 350,
  }
}
})

const App = () => {

  const page = useRecoilValue<any>(pageStateAtom)

  useEffect(() => {

    WebFont.load({
      google: {
        families: ['Inter', 'Chilanka']
      }
    })
   }, [])

   useEffect(() => {

    //window.addEventListener("resize", (event) => {window.alert('change1')} );
    
    window.Telegram.WebApp.expand()

    window.Telegram.WebApp.onEvent('viewportChanged', () => {
      if (!window.Telegram.WebApp.isExpanded){
        window.Telegram.WebApp.expand()
      }
    })

/* 
    window.Telegram.WebApp.isClosingConfirmationEnabled = true
*/
/* 
    window.Telegram.WebApp.requestContact()

    window.Telegram.WebApp.onEvent(`contactRequested`, (data:any)=>{
      if(data.status === `send`){
        return alert(`контакты отправленны прям в бот`)
      }
    })
 */
  }, [])

  return  <>
            <ThemeProvider theme={theme}>

              <Header/>

              { page === 'brands' &&
              
                <Brands/>
              }

              { page === 'models' &&
              
                <Models/>
              }
              
              { page === 'products' &&
              
                <Products/>
              }

              <Product/>

              <CallBackButton/>

              <CallBack/>
      
            </ThemeProvider>
          </>
  
}

export default App
