import { FC, useState, useEffect } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';

import axios from 'axios'

import { useRecoilState, useSetRecoilState } from 'recoil'
import { productStateAtom } from '@state/product'
import { callBackButtonStateAtom } from '@state/callBackButton'
import { callBackAttachDataStateAtom } from '@state/callBackAttachData'

import SwipeableDrawer from '@components/SwipeableDrawer'

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import CircularProgress from '@mui/material/CircularProgress';

import {milesToKilometers} from '@utils/milesToKilometers'
import { NumericFormat } from 'react-number-format';

import { styled } from '@mui/material/styles';

const Left = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
  color: theme.palette.text.secondary,
  boxShadow: 'unset',
}))
const Right = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'right',
  color: theme.palette.text.secondary,
  boxShadow: 'unset',
}))

const  {REACT_APP_API_URL} = process.env

const Product: FC<any> = () => {

  const [product, setProduct] = useRecoilState<any>(productStateAtom)
  const [loading, setLoading] = useState<any>(false)

  const setCallBackButton = useSetRecoilState<boolean>(callBackButtonStateAtom)
  const setCallBackAttachData = useSetRecoilState<any>(callBackAttachDataStateAtom)

  const [data, setData] = useState<any>(null)
  const [status, setStatus] = useState<any>(null)
  
  const onClose = () => {
    
    setProduct(null)
    setCallBackButton(false)
  }

  useEffect(() => {
    if(product){

      setLoading(true)

      axios({
        timeout: 60000,
        method: 'POST',
        url: `${REACT_APP_API_URL}/get-details`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          url: product,
        }
      })
      .then((data:any) => {

        setLoading(false);

        if(data.status===200){

          console.log(data)
          console.log(data.data.data)
          
          setCallBackAttachData(data.data.data)
          setData(data.data.data)
          setStatus(data.data.status)
          setCallBackButton(true)
        }
      })
      .catch((error:any) => {

        setLoading(false)
        console.log(error)
      })
    }
  }, [product])

  return (
    <>
        <SwipeableDrawer
          anchor={'bottom'}
          open={product}
          onClose={onClose}
          onOpen={()=>{}}
        >
          <Container maxWidth="sm" sx={{height: '90vh', pt: 5, }}>

            { loading ?
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"  // Center vertically
                justifyContent="center"  // Center horizontally
                height="80vh"  // Optional: Set a specific height for centering in the viewport
              >

                <CircularProgress color="inherit" />

                <Typography variant="subtitle2" gutterBottom  align="center" sx={{ mt: 2.5 , ml: 5, mr: 5}}>
                  Ждем актуальные данные от автоаукциона 
                </Typography>
                
              </Box>
              :
              <>

                { status === 'result-null' ?
                  
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"  // Center vertically
                    justifyContent="center"  // Center horizontally
                    height="80vh"  // Optional: Set a specific height for centering in the viewport
                  >

                    <CircularProgress color="inherit" />

                    <Typography variant="subtitle2" gutterBottom  align="center" sx={{ mt: 2.5 , ml: 5, mr: 5}}>
                      Автомабиль продан
                    </Typography>
                    
                  </Box>

                :
                    
                  <>

                    <LazyLoadImage
                    alt={''}
                    /* src={data?.pictures[0]} // use normal <img> attributes as props */
                    src={`/api/image?url=${data?.pictures[0]}`}
                    effect="blur"
                    placeholder={<CircularProgress/>}
                    style={{borderRadius: 15, width: '100%',  boxShadow: '0px 0px 16px 7px rgba(0,0,0,0.17)'}}
                    />
    {/* 
                    <Stack
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      spacing={2}
                    >
                      <Item>{data?.header}</Item>
                    </Stack>
    */}
                    <Stack spacing={2} sx={{ mt: 5, mb: 5 }}>
                    
                    { data?.mileage && 
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="flex-start"
                        spacing={2}
                      >
                        <Left>Модель</Left>
                        <Right>{data?.model}</Right>
                      </Stack>
                    }
                    { data?.mileage && 
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="flex-start"
                        spacing={2}
                      >
                        <Left>Год</Left>
                        <Right>{data?.year}</Right>
                      </Stack>
                    }   
                    { data?.mileage && 
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="flex-start"
                          spacing={2}
                        >
                          <Left>Цвет</Left>
                          <Right>{data?.color}</Right>
                        </Stack>
                      }                  
                      { data?.mileage && 
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="flex-start"
                          spacing={2}
                        >
                          <Left>Пробег</Left>
                          <Right>{milesToKilometers(data?.mileage)}<Typography variant="overline" fontSize={10}>км</Typography></Right>
                        </Stack>
                      }                  
                      { data?.fuel && data?.fuel !== 0 &&
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="flex-start"
                          spacing={2}
                        >
                          <Left>Топлива</Left>
                          <Right>{data?.fuel}</Right>
                        </Stack>
                      }
                      { data?.engine && 
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="flex-start"
                          spacing={2}
                        >
                          <Left>Двигатель</Left>
                          <Right>{data?.engine}</Right>
                        </Stack>
                      }
                      { data?.engineCapacity &&  data?.fuel !== 0 &&
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="flex-start"
                          spacing={2}
                        >
                          <Left>Объем двигателя</Left>
                          <Right>{data?.engineCapacity}</Right>
                        </Stack>
                      }
                      { data?.drive && 
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="flex-start"
                          spacing={2}
                        >
                          <Left>Привод</Left>
                          <Right>{data?.drive}</Right>
                        </Stack>
                      }
                      { data?.class && 
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="flex-start"
                          spacing={2}
                        >
                          <Left>Класс автомобиля</Left>
                          <Right>{data?.class}</Right>
                        </Stack>
                      }
                      { data?.location && 
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="flex-start"
                          spacing={2}
                        >
                          <Left>Город лота</Left>
                          <Right>{data?.location}</Right>
                        </Stack>
                      }

                      { data?.price && 
                      <>
                        <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="flex-start"
                        spacing={2}
                      >
                        <Left>Цена</Left>
                        <Right>
                        <NumericFormat allowLeadingZeros thousandSeparator=" "  decimalScale={0} displayType="text" value={data?.price?.toFixed(2)} suffix={'₽'} />
                        </Right>
                      </Stack>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="flex-start"
                        spacing={2}
                      >
                      <Left sx={{fontSize: 10}}>
                        Без учета таможни в г. Уссурийск
                      </Left>
                      </Stack>
                      </>
                      }

                    </Stack>

                    {data?.pictures.map((item: any, i:number) => (
                      <>
                        { i > 0 &&
                          <LazyLoadImage key={item}
                          alt={''}
                          /* src={item} // use normal <img> attributes as props */
                          src={`/api/image?url=${item}`}
                          effect="blur"
                          placeholder={<CircularProgress/>}
                          style={{ width: '100%', marginTop: 25, borderRadius: 15, boxShadow: '0px 0px 16px 7px rgba(0,0,0,0.17)' }}
                          />
                        }
                      </>
                    ))}

                    <Box sx={{height: 100}}/>
                    
                  </>
                }
                
              </>
            }

          </Container>

        </SwipeableDrawer>
    </>
  )
}

export default Product
