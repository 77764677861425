import { atom, selector } from "recoil"

export const callBackButtonStateAtom = atom({
  key: "callBackButtonStateAtom",
  default: false,
})

export const callBackButtonStateSelector = selector({
  key: "callBackButtonStateSelector",
  get: ({ get }) => {
    const callBackButton = get(callBackButtonStateAtom)
    return callBackButton
  }
})

