
import { FC, useEffect, useState } from 'react'

import { useRecoilValue, useRecoilState } from 'recoil'

import { brandStateAtom } from '@state/brand'
import { modelStateAtom } from '@state/model'
import { filtersStateAtom } from '@state/filters'

import SwipeableDrawer from '@components/SwipeableDrawer'

import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Unstable_Grid2'

import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';

import Price from './Price'
import EngineCapacity from './EngineCapacity'
import Fuel from './Fuel'
import Transmission from './Transmission'
import Drive from './Drive'
import Year from './Year'

interface I {
  onChange?: () => void
}

const Filters: FC<I> = ({
  onChange=()=>{}
}) => {

  const [filters, setFilters] = useRecoilState<any>(filtersStateAtom)
  const [cloneFilters, setCloneFilters] = useState<any>(null)
  
  const brand = useRecoilValue<any>(brandStateAtom)
  const model = useRecoilValue<any>(modelStateAtom)

  const lastSize = 12
  
  const onClose = () => {

    setFilters({...cloneFilters, open: false});
  }

  useEffect(() => {

    console.log(filters)

  }, [filters])

  useEffect(() => {

    if(filters.open){

      setCloneFilters(filters)
    }

    if(!model || !brand ){

      setFilters((prevState:any) => ({ ...prevState, open: false }));
    }

  }, [filters.open])

  const apply = () => {

    setFilters((prevState:any) => ({ ...prevState, open: false }));

    onChange()
  }

  return (
  <>
      <SwipeableDrawer
        anchor={'top'}
        open={filters.open}
        onClose={onClose}
        onOpen={()=>{}}
        sx={{ 
          '& .MuiPaper-root': {
            borderBottomLeftRadius: 15,
            borderBottomRightRadius: 15,
          },
        }}
      >
        <Container maxWidth="sm" sx={{ pt: 2,pb: 2, }}>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid xs={12}>
                <Price/>
              </Grid>
{/* 
              <Grid xs={12}>
                <Year/>
              </Grid>
               */}
              <Grid xs={6}>
                <EngineCapacity/>
              </Grid>
              <Grid xs={6}>
                <Drive/>
              </Grid>

              <Grid xs={6}>
                <Transmission/>
              </Grid>
              <Grid xs={6}>
                <Fuel/>
              </Grid>
              
{/*               <Grid xs={6}>
                <Fuel/>
              </Grid>
              <Grid xs={lastSize} sm={lastSize} md={lastSize} lg={lastSize} xl={lastSize}>
                <Drive/>
              </Grid> */}

              <Grid xs={lastSize} sm={lastSize} md={lastSize} lg={lastSize} xl={lastSize}>
                <Button variant="contained" color={'secondary'} fullWidth  onClick={apply} endIcon={<FilterAltOutlinedIcon />}>
                  Показать
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </SwipeableDrawer>
    </>
  )
}

export default Filters;