import { FC } from 'react'

import Box from '@mui/material/Box'

import Logo from '@components/Logo'
import ButtonFilters from '@components/ButtonFilters'
import ButtonBack from '@components/ButtonBack'

import useUrlParams from '@hooks/useUrlParams';




import * as CryptoJS from 'crypto-js';

// Encrypt
const plaintext = 'my message';
const secretKey = 'secret key 123';
const ciphertext = CryptoJS.AES.encrypt(plaintext, secretKey).toString();
console.log(ciphertext); // 'my message'
console.log(encodeURIComponent(ciphertext)); // 'my message'
console.log(encodeURI(ciphertext)); // 'my message'

// Decrypt
const bytes = CryptoJS.AES.decrypt(ciphertext, secretKey);
const originalText = bytes.toString(CryptoJS.enc.Utf8);

console.log(originalText); // 'my message'






const Header: FC<any> = () => {
  const params = useUrlParams();

  //decodeURIComponent(encodedURI)
  //encodeURIComponent()
  return (
    <>
    <Box display={'flex'} sx={{ pt:2, position: 'fixed',  /* backgroundColor: 'rgba(0,0,0,0.5)',  */height:  70, width: '100%'  }}>
      { params && params.tgWebAppStartParam &&
      <>
        {params.tgWebAppStartParam.split('#')[0]}
        {params.tgWebAppStartParam.replace('#tgWebAppData', '')}
      </>
      }
      <Logo/>
      <ButtonFilters/>
      <ButtonBack/>
    </Box>
    <Box sx={{ height: 50 }}/>
    </>
  )
}

export default Header
