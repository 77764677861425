import { atom, selector } from "recoil"

export const pageNumberStateAtom = atom({
  key: "pageNumberStateAtom",
  default: 1,
})

export const pageNumberStateSelector = selector({
  key: "pageNumberStateSelector",
  get: ({ get }) => {
    const pageNumber = get(pageNumberStateAtom)
    return pageNumber
  }
})

