import { atom, selector } from "recoil"

export const modelStateAtom = atom({
  key: "modelStateAtom",
  default: null,
})

export const modelStateSelector = selector({
  key: "modelStateSelector",
  get: ({ get }) => {
    const model = get(modelStateAtom)
    return model
  }
})

