
import { FC, useRef, useEffect, useState } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'

import axios from 'axios'

import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import CircularProgress from '@mui/material/CircularProgress'

import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil'

import { brandStateAtom } from '@state/brand'
import { modelStateAtom } from '@state/model'
import { filtersStateAtom } from '@state/filters'

import { productsStateAtom } from '@state/products'
import { productStateAtom } from '@state/product'
import { SwipeableDrawerTelegramFixedStateAtom } from '@state/SwipeableDrawerTelegramFixed'

import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import { FixedSizeList as List , ListChildComponentProps } from 'react-window'

import InfiniteLoader from "react-window-infinite-loader"

import useMeasure from '@hooks/useMeasure'

import { NumericFormat } from 'react-number-format'

import { styled } from '@mui/material/styles'

import Filters from '@components/Filters'
import filterObject from '@utils/filterObject'

import {milesToKilometers} from '@utils/milesToKilometers'

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';

import useMediaQuery from '@mui/material/useMediaQuery';

// Определение типа для элемента данных
type CarData = {
  header: string;
  brend: string;
  model: string;
  year: number;
  url: string;
  img: string;
  mileage: number;
  price: number;
}

// Стрелочная функция для объединения данных и удаления дубликатов по полю url
const mergeCarDataWithNoDuplicates = (
  existingData: CarData[],
  newData: CarData[]
): CarData[] => {
  // Создайте множество для хранения уникальных url
  const uniqueUrls = new Set(existingData.map((item) => item.url));

  // Фильтруйте новые данные, оставляя только те, чьи url ещё не существуют в уникальных url
  const filteredNewData = newData.filter((item) => !uniqueUrls.has(item.url));

  // Объедините существующие данные и отфильтрованные новые данные
  const mergedData = [...existingData, ...filteredNewData];

  return mergedData;
}

const renderRow = (props: ListChildComponentProps) => {

  const { data, index, style } = props;

  return (
    <ListItem style={style} key={index} component="div" disablePadding onClick={() => data.onClick(data?.data[index].url, data?.data[index].header)}>
      <ListItemButton sx={{padding: 0}} disableRipple>
        <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        spacing={0}
        sx={{ pt: 1, width: '100%' }}
        >
          <Item >
            <LazyLoadImage
              alt={''}
              /* src={`http://localhost:1403/api/image?url=${data?.data[index].img}`} */
              src={`/api/image?url=${data?.data[index].img}`}
              width={128}
              effect="blur"
              placeholder={<CircularProgress/>}
              style={{borderRadius: 10, boxShadow: '0px 0px 6px 3px rgba(0,0,0,0.1)'}}
              />
          </Item>
          <Item sx={{width: '70%'}}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
              spacing={0}
            >
              <Item>Год</Item>
              <Item>{data?.data[index]?.year}</Item>
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
              spacing={0}
            >
              <Item>Пробег</Item>
              <Item>{milesToKilometers(data?.data[index]?.mileage?.toFixed(0))}км</Item>
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
              spacing={0}
            >
              <Item>
                Цена
              </Item>
              <Item>
                <NumericFormat allowLeadingZeros thousandSeparator=" "  decimalScale={0} displayType="text" value={data?.data[index]?.price?.toFixed(2)} suffix={'₽'} />
              </Item>
            </Stack>

            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
              spacing={0}
              
            >
              <Item sx={{fontSize: 10, pr: 0}}>
                Без учета таможни в г. Уссурийск
              </Item>
            </Stack>
            
          </Item>

        </Stack>
      </ListItemButton>
    </ListItem>
  )
}
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  marginTop: 0,
  marginBottom: 0,
  marginLeft: 0,
  paddingTop: 1,
  lineHeight: 1.25,
  color: theme.palette.text.secondary,
}));

const  {REACT_APP_API_URL} = process.env

const Brands: FC<any> = () => {
  
  const [swipeableDrawerTelegramFixed, SetSwipeableDrawerTelegramFixed] = useRecoilState<any>(SwipeableDrawerTelegramFixedStateAtom)

  const [products, setProducts] = useRecoilState<any>(productsStateAtom)
  const setProduct = useSetRecoilState<any>(productStateAtom)
  
  const brand = useRecoilValue<any>(brandStateAtom)
  const model = useRecoilValue<any>(modelStateAtom)

  const filters = useRecoilValue<any>(filtersStateAtom)

  const [status, setStatus] = useState<any>(null)

  const pageNumber = useRef(1)
  const pageStep = useRef(1)
  const hasNextPage = useRef(true)

  const [loading, setLoading] = useState<boolean>(false)
  
  const { windowSize } = useMeasure()

  useEffect(() => {

    console.log(`filters:`)
    console.log(filters)
    console.log(filterObject(filters))
    console.log(Object.keys(filterObject(filters)).length === 0)

  }, [filters])

  const [additionalLoad, setAdditionalLoad] = useState(false)

  const onClick = (url: string, name: string) => {
    console.log(`url: ${url}, name: ${name}`)
    setProduct(url)
    //setPage('products')
  }

  const loadData = () => {

    if(!hasNextPage.current){
      return;
    }

    setLoading(true)

    if((pageNumber.current===1&&pageStep.current===2)||(pageNumber.current!==1)){
      setAdditionalLoad(true)
    }

    axios({
      method: 'POST',
      url: `${REACT_APP_API_URL}/get-products`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        brand: brand,
        model: model,
        pageStep: pageStep.current,
        page: pageNumber.current,
        filters: filters,
      }
    })
    .then((data) => {

      setLoading(false)
      setAdditionalLoad(false)

      if(data.status===200){

        setLoading(false)

        setProducts((prevData:any) => mergeCarDataWithNoDuplicates(prevData, data.data.data));

        setStatus(data.data.status)

        if(data.data.totalPages === pageNumber.current){
          hasNextPage.current = false 
        }
        console.log(data.data.data)

/*           if(pageStep.current===1 && data.data.status==='successful' && Object.keys(filterObject(filters)).length === 0){
          pageStep.current = 2
          loadData()
        } */
      }
    })
    .catch((error:any) => {

      setLoading(false)
      setAdditionalLoad(false)

      console.log(error)
    })
    
  }

  const loadMoreData = () => {

    console.log('loadMoreData')
    
    if (!loading) {

      pageNumber.current = pageNumber.current + 1

      loadData()
    }
  }
  
  useEffect(() => {

    console.log(`models: ${products.length}`)

    if(products.length===0){

      loadData()
    }
  }, [])

  const isItemLoaded = (index: number) => {
    return index < products.length;
  }

  /* стар фикса телеграм окна при виртуальном скроле */
  const [scrolled, setScrolled] = useState(false);

  const handleScroll = (scroll:any) => {

    if (scroll.scrollOffset > 10) {
      setScrolled(true);
    }else
    if (scroll.scrollOffset < 10) {
      setScrolled(false);
    }
  };

  useEffect(()=>{

    if(scrolled){

      if(swipeableDrawerTelegramFixed === 0){
  
        const elem = document.body

        if(elem){
  
          elem.style.height = '101vh'
        }
        window.scrollTo(0, 1)

      }

      SetSwipeableDrawerTelegramFixed((prevState:number) => ( prevState + 1 ));

    }else{
      if(swipeableDrawerTelegramFixed === 1){
  
        window.scrollTo(0, 0)

        const elem = document.body

        if(elem){

          elem.style.height = '100vh'
        }

      }
      SetSwipeableDrawerTelegramFixed((prevState:number) => ( prevState > 0 ? prevState - 1 : prevState ));

    }
  }, [scrolled])

  /* енд фикса телеграм окна при виртуальном скроле */

  const onChangeFilters = () => {

    pageNumber.current = 1
    pageStep.current = 1

    setProducts([])
    hasNextPage.current = true

    loadData()
  }
 
  const matchesUseMediaQuery = useMediaQuery('(max-width:320px)');

  const pLR = matchesUseMediaQuery ? 0 : '16px'

  return <Container maxWidth="sm" sx={{pl: pLR, pr: pLR}} >

          <Typography variant="subtitle1" gutterBottom color="primary" align="center" sx={{ mt: 1}}  onClick={()=>{setAdditionalLoad(true)}}>

            {brand} {model} 

          </Typography>

          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={additionalLoad}
            onClose={()=>{}}
            message="Идет загрузка объявлений"
            key={'loadingnewcontent'}
            sx={{borderRadius: '8px', top: '64px', }}
          >
            <Alert
            onClose={()=>{}}
            icon={<CircularProgress size={18} color={'inherit'}/>} 
            severity="info"
            variant="filled"
            action={
              <></>
/*               <Button size="small" aria-label="close" color="primary" onClick={()=>{setAdditionalLoad(false)}} sx={{p: 0.5, fontSize: 10, backgroundColor: 'unset'}}>
                Скрыть
              </Button> */
            }
            style={{
              backgroundColor: 'rgba(0,0,0,0.75)',
              color: '#fff',
              fontSize: 12,
            }}
            >
              Идет загрузка...

            </Alert>
          </Snackbar>

          { products?.length > 0 &&

            <Box sx={{ width: windowSize.width - 50}}>

              <InfiniteLoader
                isItemLoaded={isItemLoaded}
                itemCount={products.length + 1}
                loadMoreItems={loadMoreData}
              >
              {({ onItemsRendered, ref }) => (
                <List
                  height={windowSize.height - 115}
                  width={windowSize.width - ( matchesUseMediaQuery ? 0 : 40)}
                  
                  itemCount={products?.length}
                  itemSize={140}
                  itemData={{
                    data: products,
                    onClick: onClick,
                  }}
                  onItemsRendered={onItemsRendered}
                  ref={ref}
                  onScroll={handleScroll} // Обработчик события прокрутки
                >
                  {renderRow}
                </List>
              )}
          
            </InfiniteLoader>
  
            </Box>
          }

          { loading && pageNumber.current === 1 && pageStep.current === 1 ?
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"  // Center vertically
              justifyContent="center"  // Center horizontally
              height="70vh"  // Optional: Set a specific height for centering in the viewport
            >

              <CircularProgress color="inherit" />

              <Typography variant="subtitle2" gutterBottom  align="center" sx={{ mt: 2.5 , ml: 5, mr: 5}}>
                
                { Object.keys(filterObject(filters)).length === 0 ?
                
                  <>Загружаем актуальные данные </>
                  :
                  <>Подбираем по заданным параметрам</>
                }

              </Typography>
              
            </Box>
          :
          <>

            { status === 'result-null' ?
              
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"  // Center vertically
                justifyContent="center"  // Center horizontally
                height="80vh"  // Optional: Set a specific height for centering in the viewport
              >

                <Typography variant="subtitle2" gutterBottom  align="center" sx={{ mt: 2.5 , ml: 5, mr: 5}}>
                  По вашему запросу нечего не найдено 
                </Typography>
                
              </Box>

            :

              <></>
            
            }
            </>
          }

          {  !loading && products.length === 0 &&

            <Typography variant="subtitle2" gutterBottom color="primary" align="center" sx={{ mt: 1, pt: '50%'}}>

              Что-то пошло не так! Данные, которую вы хотели получить, потеряны!

            </Typography>
          }

          <Filters onChange={onChangeFilters}/>

        </Container>
}

export default Brands
