import { FC, useEffect } from 'react'

import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil'
import { productStateAtom } from '@state/product'
import { callBackButtonStateAtom } from '@state/callBackButton'
import { callbackStateAtom } from '@state/callback'

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade';

import NotesIcon from '@mui/icons-material/Notes';

const CallBackButton: FC = () => {

  const [product, setProduct] = useRecoilState<any>(productStateAtom)
  const setCallBackButton = useSetRecoilState<boolean>(callBackButtonStateAtom)

  const setCallback = useSetRecoilState<any>(callbackStateAtom)
  
  const callBackButton = useRecoilValue<boolean>(callBackButtonStateAtom)

  return (
    <Fade in={callBackButton} timeout={500}>

      <Box sx={{
        position: 'fixed',
        top: window.Telegram.WebApp.viewportHeight - 80,
        left: 10,
        zIndex: 1201,
      }}>

        <Button variant="contained" color={'secondary'} fullWidth onClick={()=>{
          setCallback(true)

          setProduct(null)
          setCallBackButton(false)
        }}
        sx={{
          width: 'calc(100vw - 20px)',
        }}
        endIcon={<NotesIcon/>}
        >
          Оставить заявку
        </Button>

      </Box>
    </Fade>
  )
}

export default CallBackButton