interface Filter {
  key: string;
  name: string;
}

type FilterObject = {
  [key: string]: Filter | FilterObject;
};

const filterObject = (obj: FilterObject): FilterObject => {
  const result: FilterObject = {};

  for (const key in obj) {
    if (key !== "open" && obj[key]) {
      if (typeof obj[key] === "object" && obj[key].key && obj[key].name) {
        result[key] = {
          key: obj[key].key,
          name: obj[key].name,
        } as Filter; // Cast to Filter
      } else if (typeof obj[key] === "object") {
        const nestedResult = filterObject(obj[key] as FilterObject);
        if (Object.keys(nestedResult).length > 0) {
          result[key] = nestedResult;
        }
      }
    }
  }

  return result;
};
/* 
const filters: FilterObject = {}; // Replace with your filters object
const sortFilters: FilterObject = filterObject(filters); */

export default filterObject

export type { FilterObject, Filter }